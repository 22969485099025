<div class="hs-increase-number-container">
  <input [formControl]="control" style="display:none;" />
  <strong>{{ label }}</strong>
  <div class="increase-number-controls" [class.is-error]="isInvalid()">
    <div class="d-flex align-items-center justify-content-center button minus" (click)="onMinus()"></div>
    <div class="d-flex align-items-center justify-content-center number">{{ control.value || 0 }}</div>
    <div class="d-flex align-items-center justify-content-center button plus" (click)="onPlus()"></div>
  </div>
  <div class='errors'>
    <ng-template *ngIf='isInvalid()' [ngTemplateOutlet]="error" [ngTemplateOutletContext]="{ errors: control!.errors }"></ng-template>
  </div>
</div>


<ng-template #error let-errors="errors">
  <span *ngIf='errors["required"]'>Required field</span>
</ng-template>
