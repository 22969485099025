<div class="hs-documents-upload">
  <input [formControl]="control" hidden >
  <ngx-dropzone class="hs-dropzone-container" (change)="onSelect($event)" [accept]="accept">
    <ngx-dropzone-label>+ {{ placeholder }}</ngx-dropzone-label>
  </ngx-dropzone>
  <div class="grid-preview">
    <ngx-dropzone-preview
      class="hs-document-preview"
      *ngFor="let f of files; let i = index"
      [removable]="!!f"
      (click)="onPreviewClick(i)"
      (removed)="onRemove(f)">
      <ngx-dropzone-label>
        <div class="hs-preview-container" [class.selected]="i === selectedImage">
          <img *ngIf="imageSrc[i] && f" [src]="imageSrc[i]">
          <div *ngIf="!imageSrc[i] && f">{{ f?.name }}</div>
          <div class="file-name" *ngIf="showName && f">{{ f?.name }}</div>
          <div *ngIf="!f" class="placeholder">+</div>
        </div>
      </ngx-dropzone-label>
    </ngx-dropzone-preview>
  </div>
</div>
