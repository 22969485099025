import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../../base-control-value-accessor';

@Component({
  selector: 'hs-increase-number',
  templateUrl: './increase-number.component.html',
  styleUrls: ['./increase-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: IncreaseNumberComponent,
      multi: true,
    },
  ]
})
export class IncreaseNumberComponent extends BaseControlValueAccessor implements OnInit {
  @Input() label = '';

  constructor(
    @Optional() controlContainer: ControlContainer,
  ) {
    super(controlContainer)
  }

  ngOnInit(): void {
  }

  onPlus() {
    if (this.control.value < 100) {
      this.setValue(this.control.value + 1);
    }
  }

  onMinus() {
    if (this.control.value > 1) {
      this.setValue(this.control.value - 1);
    }
  }

  setValue(value: number) {
    this.control.setValue(value);
  }

  isInvalid() {
    return this.control && (
      (this.control.invalid && this.control.touched) ||
      (this.control.invalid && this.control.dirty)
    )
  }
}
