import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'hs-step-navigation',
  templateUrl: './step-navigation.component.html',
  styleUrls: ['./step-navigation.component.scss']
})
export class StepNavigationComponent implements OnChanges {
  @Input() currentIndex: number;
  @Input() tabsCount: number;

  public sectionWidth: string;
  public sectionPosition: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentIndex.previousValue === changes.currentIndex.currentValue
      && changes.tabsCount.previousValue === changes.tabsCount.currentValue) {
      return;
    }

    this.calculateSectionParameters();
  }

  calculateSectionParameters(): void {
    const wrapper = document.querySelector('.step-navigation-wrapper') as HTMLElement;
    const offsetWidth = wrapper && wrapper.offsetWidth;
    const sectionWidth = offsetWidth / this.tabsCount;
    this.sectionWidth = `${sectionWidth}px`;
    this.sectionPosition = `${sectionWidth * this.currentIndex}px`;
  }
}
