<div [formGroup]="formGroup">
  <div class="filter">
    <h3 class="title">What type of space do you need?</h3>
    <div class="controls controls-row">
      <hs-radio-group
        direction="row"
        formControlName="spaceType"
        [items]="guestExpects"
      ></hs-radio-group>
    </div>
  </div>
  <div class="filter">
    <h3 class="title">Do you want to have a dedicated space?</h3>
    <div class="controls controls-row">
      <hs-radio-group
        direction="row"
        formControlName="spaceDedicated"
        [items]="dedicates"
      ></hs-radio-group>
    </div>
  </div>
  <div class="filter">
    <h3 class="title">Rooms and sleeping arrangements</h3>
    <div class="controls">
      <div class="control">
        <span class="label">Bedrooms</span>
        <hs-increase-number
          formControlName="bedroomNumberRange"
        ></hs-increase-number>
      </div>
      <div class="control">
        <span class="label">Bathrooms</span>
        <hs-increase-number
          formControlName="bathroomNumberRange"
        ></hs-increase-number>
      </div>
    </div>
  </div>
  <div class="filter">
    <h3 class="title">Additional</h3>
    <div class="controls">
      <hs-select-group
        formControlName="features"
        [options]="additionalOptions"
      ></hs-select-group>
    </div>
  </div>
</div>
