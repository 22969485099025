import { Injectable } from '@angular/core';
import { NgForage } from 'ngforage';
import { defer, Observable } from 'rxjs';

export enum StorageKey {
  Token = 'token',
  Language = 'lang',
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    private ngForage: NgForage,
  ) {
  }

  set<T>(key: string | StorageKey, value: T): Observable<T> {
    return defer(() => this.ngForage.setItem(key, value));
  }

  get<T>(key: string | StorageKey): Observable<T | null> {
    return defer(() => this.ngForage.getItem<T>(key));
  }

  remove<T>(key: string | StorageKey): Observable<void> {
    return defer(() => this.ngForage.removeItem(key));
  }

  clear(): Observable<void> {
    return defer(() => this.ngForage.clear());
  }
}
