import { Component, Input, OnInit } from '@angular/core';
import { SelectOption } from '@hs/models';
import * as moment from 'moment';
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'hs-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {
  @Input() data: any;

  public fromDate: NgbDate;
  public toDate: NgbDate;
  public isFeaturesExpanded: boolean;
  public additionalOptions: SelectOption[] = [
    { value: 'wifi', label: 'Wi-Fi' },
    { value: 'satelite', label: 'Satelite' },
    { value: 'hifi', label: 'Hi-Fi Sound' },
    { value: 'home-cinema', label: 'Home Cinema' },
    { value: 'working-space', label: 'Working space' },
    { value: 'library', label: 'Library' },
    { value: 'fireplace', label: 'Fireplace' },
    { value: 'louge', label: 'Lounge' },
  ];

  constructor() { }

  ngOnInit(): void {
    if (!this.data) {
      return;
    }
    this.fromDate = this._getFormattedDate(this.data.fromDate);
    this.toDate = this._getFormattedDate(this.data.toDate);
  }

  private _getFormattedDate(timestamp) {
    const date = moment(timestamp);
    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date()
    } as NgbDate;
  }
  getPropertyLabel(property: string): string {
    return this.additionalOptions.find(item => item.value === property)?.label;
  }

  toggleFeatures () {
    this.isFeaturesExpanded = !this.isFeaturesExpanded;
  }
}
