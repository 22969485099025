import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { SearchService } from "@hs/core/services/data/search.service";

@Component({
  selector: 'hs-area-filter',
  templateUrl: './area-filter.component.html',
  styleUrls: ['./area-filter.component.scss']
})
export class AreaFilterComponent implements OnInit, OnChanges {
  @Input() filter: TemplateRef<any>;
  @Input() activeFilter: string;
  @Output() changeFilters = new EventEmitter<void>();
  public isFilled: boolean;
  public name: string = 'dimensionRange';
  public defaultLabel: string = 'm';
  public label: string;
  public fromDimension: number = 0
  public toDimension: number = 0
  timeout;

  constructor(
    private _searchService: SearchService
  ) { }

  get filters () {
    return this._searchService.searchDTO;
  }

  ngOnInit(): void {
    this.label = this.defaultLabel;
    this._searchService.onFiltersChange.subscribe(() => {
      if(!this.filters[this.name]) {
        this.fromDimension = this.toDimension = 0;
      } else {
        this.fromDimension = this.filters[this.name].min;
        this.toDimension = this.filters[this.name].max;
      }
      this.label = this.filters[this.name] && this.filters[this.name].min
        ? `${this.filters[this.name].min}-${this.filters[this.name].max} m`
        : this.defaultLabel;
      this.isFilled = this.label !== this.defaultLabel;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeFilter
      && changes.activeFilter.previousValue === this.name
      && changes.activeFilter.currentValue !== this.name
      && document.scrollingElement.clientWidth <= 767) {
      const filter = this.fromDimension && this.fromDimension
        ? {
          min: this.fromDimension,
          max: this.fromDimension
        }
        : null;

      this._searchService.searchDTO = {
        ...this.filters,
        [this.name]: filter
      }
      this._searchService.onFiltersChange.next();
      this._searchService.getSearchCount(this._searchService.searchDTO);
    }
  }

  public onChange(event, field): void {
    if (event.target.value < 0) {
      this[field] = 0;
    }
  }

  public clearFilter(): void {
    this.fromDimension = this.toDimension = 0;
    this._searchService.searchDTO = {
      ...this.filters,
      [this.name]: null
    }
    this._searchService.onFiltersChange.next();
    this.changeFilters.next();
  }

  public applyFilter(): void {
    this.toDimension = this.toDimension < this.fromDimension ? this.fromDimension : this.toDimension;
    const filter = this.fromDimension && this.fromDimension
    ? {
        min: this.fromDimension,
        max: this.toDimension
      }
    : null;

    this._searchService.searchDTO = {
      ...this.filters,
      [this.name]: filter
    }
    this._searchService.onFiltersChange.next();
    this.changeFilters.next();
  }
}
