import { Account } from './user';

export enum HOUSE_TYPES {
  APARTMENT = 'APARTMENT',
  DETACHED_HOUSE = 'DETACHED_HOUSE',
  COTTAGE = 'COTTAGE',
  MANSION = 'MANSION',
  VILLA = 'VILLA',
  MANOR = 'MANOR',
  HOUSE = 'HOUSE'
}

export enum HOUSE_CLASSES {
  HIGH = 'HIGH',
  MIDDLE = 'MIDDLE',
  LOW = 'LOW'
}

export enum HOUSE_INTERIORS_STYLES {
  ABSTRACT = 'ABSTRACT',
  AMERICAN_COLONIAL = 'AMERICAN_COLONIAL',
  AMISH = 'AMISH',
  ARABIAN = 'ARABIAN',
  ART_DECO = 'ART_DECO',
  ART_NOUVEAU = 'ART_NOUVEAU'
}

export interface House {
  id: number;
  tempDataId?: number | string,
  isOwn?: boolean,
  createdDate?: any,
  houseType: HOUSE_TYPES;
  houseInteriorStyle?: string;
  guestNumber?: number;
  spaceDedicated?: boolean;
  houseClass: HOUSE_CLASSES;
  dimension: number;
  views: number;
  rating: number;
  description: string;
  availableFrom?: number,
  availableTo?: number,
  location: Location;
  features: string[];
  account: Account;
  houseViewPermissions: any;
  houseViews: any;
  images: Image[];
  ownershipDocuments: any[];
  rules?: any;
  title: string;
  roomInfoList?: any;
  bathroomNumber?: number;
  bedroomNumber?: number;
}

export interface Location {
  address:	string;
  city:	string;
  country:	string;
  detailed:	boolean;
  latitude:	number;
  longitude:	number;
  apartmentNumber?:	number;
  postalCode?: string;
  state?: string;
}

export interface Image {
  baseUrl:	string;
  byteSize:	number;
  extension:	string;
  generatedKey:	string;
  id:	number;
  minimizedImages: any;
  originalName:	string;
  url:	string;
}

//
export enum GUEST_EXPECT {
  ENTIRE = 'ENTIRE_PLACE',
  PRIVATE = 'PRIVATE_ROOM'
}
