<div>
  <h2 class="hs-title">{{title}}</h2>
  <div class="hs-title input-label"><span class="required">*</span>{{ 'auth.verify_code' | transloco }}</div>

  <div class="input-wrapper">
    <ng-otp-input
      #ngOtpInput
      (onInputChange)="onCodeChange($event)"
      [config]="config">
    </ng-otp-input>

    <div class="validation-message" *ngIf="isCodeComplete && !isLoading">
      <span class="valid" *ngIf="isCodeValid">{{'auth.email_confirmed' | transloco}}</span>
      <span class="invalid" *ngIf="!isCodeValid">{{'auth.wrong_code' | transloco}}</span>
    </div>
  </div>
  <div class="not-recieve">{{'auth.not_recieve_email' | transloco}}</div>
  <div class="resend" (click)="resendConfirmationCode()">{{'auth.resend_email' | transloco}} {{timerString}}</div>
</div>
