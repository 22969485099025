import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { NumberRange, SearchField } from "@hs/core/interfaces/search.interface";
import * as moment from 'moment';
import { SearchService } from "@hs/core/services/data/search.service";

@Component({
  selector: 'hs-dates-filter',
  templateUrl: './dates-filter.component.html',
  styleUrls: [ './dates-filter.component.scss' ]
})
export class DatesFilterComponent implements OnInit, OnChanges {
  @Input() filter: TemplateRef<any>;
  @Input() activeFilter: string;
  @Output() changeFilters = new EventEmitter<void>();
  public isFilled: boolean;
  public name: string = 'availableTimeRange';
  public defaultLabel: string = 'Dates';
  public label: string;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  public hoveredDate: NgbDate | null = null;
  public isDaySingle = true;
  public isHover = false;
  public availableDates: NumberRange;

  constructor(
    private _searchService: SearchService
  ) {}

  get filters () {
    return this._searchService.searchDTO;
  }

  ngOnInit(): void {
    this.label = this.defaultLabel;
    this._searchService.onFiltersChange.subscribe(() => {
      if(!this.filters[this.name]) {
        delete this.fromDate;
        delete this.toDate;
        delete this.availableDates;
      } else {
        this.fromDate = this.filters[this.name].min;
        this.toDate = this.filters[this.name].max;
        this.availableDates = {
          min: new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).getTime(),
          max: new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).getTime()
        }
      }

      this.label = this.filters[this.name]
      && this.filters[this.name].min
      && this.filters[this.name].max
        ? `${ moment(this.filters[this.name].min).format('D MMM') } - ${ moment(this.filters[this.name].max).format('D MMM') }`
        : this.defaultLabel;
      this.isFilled = this.label !== this.defaultLabel;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeFilter
      && changes.activeFilter.previousValue === this.name
      && changes.activeFilter.currentValue !== this.name
      && document.scrollingElement.clientWidth <= 767) {
      this._searchService.searchDTO = {
        ...this.filters,
        [this.name]: this.availableDates || null
      }
      this._searchService.onFiltersChange.next();
      this._searchService.getSearchCount(this._searchService.searchDTO);
    }
  }

  public isFirstDay(date: NgbDate): boolean {
    return this.fromDate && date.equals(this.fromDate)
  }

  public isLastDay(date: NgbDate): boolean {
    return (this.isHover
      && date.equals(this.hoveredDate)
      && !this.toDate
      && this.fromDate
      && !date.before(this.fromDate)
    ) || this.toDate && date.equals(this.toDate);
  }

  public isSingle(date: NgbDate): boolean {
    return (!this.isHover && this.fromDate && date.equals(this.fromDate) && !this.toDate)
      || (this.isDaySingle && this.fromDate && date.equals(this.fromDate) && !this.toDate);
  }

  public isHovered(date: NgbDate): boolean {
    return date.equals(this.hoveredDate)
      && !this.isFirstDay(date)
      && !this.isLastDay(date)
      && !this.isRange(date);
  }

  public isFocused(date: NgbDate): boolean {
    return date.equals(this.hoveredDate) || this.isFirstDay(date) || this.isLastDay(date);
  }

  public isInside(date: NgbDate): boolean {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isRange(date: NgbDate): boolean {
    return (this.toDate && date.after(this.fromDate) && date.before(this.toDate))
      || this.isInside(date);
  }

  public onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.isDaySingle = true;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.isDaySingle = true;
    }

    if (this.fromDate && this.toDate) {
      this.availableDates = {
        min: new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).getTime(),
        max: new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).getTime()
      }
    }
  }

  public onDateHover(date): void {
    this.hoveredDate = date;
    this.isHover = !!date;
    this.isDaySingle = date && !date.after(this.fromDate);
  }

  public clearFilter(): void {
    delete this.fromDate;
    delete this.toDate;
    delete this.availableDates;

    this._searchService.searchDTO = {
      ...this.filters,
      [this.name]: null
    }
    this._searchService.onFiltersChange.next();
    this.changeFilters.next();
  }

  public applyFilter(): void {
    this._searchService.searchDTO = {
      ...this.filters,
      [this.name]: this.availableDates || null
    }
    this._searchService.onFiltersChange.next();
    this.changeFilters.next();
  }
}
