import { Directive, HostBinding, Input, OnInit } from '@angular/core';

export type HsButtonType = 'primary' | 'default' | null;

@Directive({
  selector: 'button[hsButton]'
})
export class HsButtonDirective implements OnInit {
  @HostBinding('class.hs-btn') hsBtn = true;
  @HostBinding('class.hs-btn-primary') hsBtnPrimary = false;

  @Input() hsType: HsButtonType = null;
  constructor() { }

  ngOnInit() {
    this.hsBtnPrimary = this.hsType === 'primary';
  }
}
