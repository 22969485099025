import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { NumberRange, SearchDTO, SearchField } from "@hs/core/interfaces/search.interface";

@Component({
  selector: 'hs-more-filter',
  templateUrl: './more-filter.component.html',
  styleUrls: ['./more-filter.component.scss']
})
export class MoreFilterComponent implements OnChanges {
  @Input() filter: TemplateRef<any>;
  @Input() filters: SearchDTO;
  @Input() active: string;
  @Output() changeFilters = new EventEmitter<SearchField>();
  public isFilled: boolean;
  public name: string = 'more';
  public defaultLabel: string = 'More';
  public label: string;

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.filters.previousValue || ( changes.filters.previousValue
      && changes.filters.previousValue[this.name] !== changes.filters.currentValue[this.name])
    ) {
      this.label = this.defaultLabel;
    }
  }
}
