import { Component, EventEmitter, Input, OnInit, Optional, Output, Self, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'hs-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input() label = ''
  @Input() placeholder = ''
  @Input() type = 'text'
  @Input() theme = 'default'
  @Input() customError: string;
  @Input() pattern: RegExp;
  @Input() submitted: boolean;
  @Input() showPasswordToggle: boolean;
  @Input() trimRedundantZero: boolean;
  @Input() leftIcon: TemplateRef<any>;
  @Input() rightIcon: TemplateRef<any>;
  @Input() rightIconLabel: string;
  @Input() dropSpecialCharacters = true;

  @Output() onBlur = new EventEmitter();
  @Output() onFocus = new EventEmitter();

  isRequired = false
  onChange: (value: any) => void;
  onTouched: () => void;
  control = new FormControl();

  constructor(
    @Optional() @Self() public ngControl: NgControl
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    if (this.ngControl.control instanceof FormControl && this.ngControl.control.validator) {
      const validator = this.ngControl.control.validator(new FormControl(''))
      this.isRequired = Boolean(validator && validator.hasOwnProperty('required'))
    }
  }

  innerChange() {
    this.onChange(this.control.value)
  }

  innerTouch() {
    if (this.trimRedundantZero) {
      const value = parseFloat(this.control.value) || ''
      this.onChange(value)
      this.writeValue(value.toString())
    }
    this.onBlur.next();
    this.onTouched();
  }

  onInputFocus() {
    this.onFocus.next();
  }

  writeValue(value: string): void {
    this.control.setValue(value || '');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  isInvalid() {
    return this.ngControl.control && (
      (this.ngControl.control.invalid && this.ngControl.control.touched) ||
      (this.ngControl.control.invalid && this.ngControl.control.dirty) ||
      (this.ngControl.control.invalid && this.submitted)
    )
  }

}
