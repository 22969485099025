<router-outlet></router-outlet>

<div id="mobile-menu" class="menu-wrapper" [class.expanded]="isOpenMenu">
  <hs-header *ngIf="isOpenMenu" class="header" hideShadow="true"></hs-header>
  <div class="body" *ngIf="isOpenMenu">
    <div>
      <div class="menu" routerLink="/">Home</div>
      <div class="menu" routerLink="/places" (click)="toggleMenu()">My places</div>
      <div class="menu">Saved</div>
      <div class="menu">Messages</div>
      <div class="menu" routerLink="/add-my-property/" (click)="toggleMenu()">Add new house</div>
      <div class="menu" routerLink="/profile" (click)="toggleMenu()">Profile</div>
    </div>
  </div>

  <div class="buttons">
    <div class="search-bar" *ngIf="!isOpenMenu && (path === '/home' || path === '/places/search')">
      <div class="input-group mb-3 rounded-pill">
        <input class="form-control rounded-pill" placeholder="Where are you going to?" (input)="onInputChange($event)">
        <div class="input-group-prepend">
          <img alt="" src="assets/images/location.svg">
        </div>
      </div>
    </div>
    <div
      class="filters"
      [class.active]="isFiltersActive"
      (click)="toggleFilters(true)"
      *ngIf="!isOpenMenu && (path === '/home' || path === '/places/search')">
      <div class="image">
        <img alt="" [src]="isFiltersActive ? 'assets/images/filters-white.svg' : 'assets/images/filters.svg'">
      </div>
      <span class="results" *ngIf="isFiltersNotEmpty && isFiltersActive">Show {{searchResultsCount}} results</span>
    </div>
    <div class="bottom-menu menu" (click)="toggleMenu()">
      <img alt="" [src]="isOpenMenu ?'assets/images/close-white.svg' : 'assets/images/menu.svg'">
    </div>
  </div>
</div>

<div class="filters-container" *ngIf="isFiltersActive">
  <div class="filters-header">
    <div class="clear-all" (click)="clearAllClick()">Clear all</div>
    <div class="close-filters" (click)="toggleFilters()">
      <img alt="" src="../../../../assets/images/close.svg">
    </div>
  </div>
  <hs-filters defaultPopup="availableTimeRange" [clearAllSubject]="clearAll"></hs-filters>
</div>
<div class="substrate" *ngIf="isFiltersActive" (click)="toggleFilters()"></div>
