import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@hs/auth/services/auth.service';
import { UserService } from '@hs/core/services';
import { AppContext } from '@hs/core/services/app-context.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'hs-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  isLoading = false;

  constructor(
    private userService: UserService,
    private appContext: AppContext,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getUserProfile()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (userProfile) => {
          this.appContext.setUser(userProfile);
        },
        error: err => {
          console.error(err);
          this.authService.signOut().subscribe(() => this.router.navigateByUrl('/auth/sign-in'))
        }
      })
  }

}
