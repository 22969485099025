<ng-container
  *ngIf="filter"
  [ngTemplateOutlet]="filter"
  [ngTemplateOutletContext]="{
    content: content,
    name: name,
    label: label,
    isFilled: isFilled
  }"
></ng-container>

<ng-template #content>
  <div [formGroup]="formGroup" class="group-wrapper">
    <hs-select-group
      colsCount="1"
      formControlName="types"
      [options]="options"
    ></hs-select-group>
  </div>
  <div class="filter-popup-footer">
    <button hsButton hsType="default" class="hs-btn-default clear" (click)="clearFilter()">Clear</button>
    <button hsButton hsType="primary" class="hs-btn-default" (click)="applyFilter()">Apply</button>
  </div>
</ng-template>
