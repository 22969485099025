import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from "@hs/shared/shared.module";
import { FiltersComponent } from "@hs/filters/containers/filters/filters.component";
import { DatesFilterComponent } from "@hs/filters/components/dates-filter/dates-filter.component";
import { AreaFilterComponent } from "@hs/filters/components/area-filter/area-filter.component";
import { GuestsFilterComponent } from "@hs/filters/components/guests-filter/guests-filter.component";
import { TypeFilterComponent } from "@hs/filters/components/type-filter/type-filter.component";
import { MoreFilterContentComponent } from "@hs/filters/components/more-filter-content/more-filter-content.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MoreFiltersModalComponent } from "@hs/filters/components/more-filters-modal/more-filters-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MoreFilterComponent } from "@hs/filters/components/more-filter/more-filter.component";

@NgModule({
  declarations: [
    FiltersComponent,
    DatesFilterComponent,
    AreaFilterComponent,
    GuestsFilterComponent,
    TypeFilterComponent,
    MoreFilterComponent,
    MoreFilterContentComponent,
    MoreFiltersModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    NgbDatepickerModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule
  ],
  exports: [
    FiltersComponent
  ],
  entryComponents: [
    MoreFiltersModalComponent
  ]
})
export class FiltersModule { }
