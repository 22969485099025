import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioItem } from '@hs/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseControlValueAccessor } from '../../base-control-value-accessor';

@Component({
  selector: 'hs-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioGroupComponent,
      multi: true,
    },
  ]
})
export class RadioGroupComponent extends BaseControlValueAccessor implements OnInit, OnDestroy {

  @Input() items: RadioItem[] = [];
  @Input() direction: string = 'column';

  radioForm: FormGroup;
  get radio() {
    return this.radioForm.get('radio') as FormControl;
  }
  private destroyed$ = new Subject<void>();

  constructor(
    @Optional() controlContainer: ControlContainer,
    private fb: FormBuilder
  ) {
    super(controlContainer)
  }

  ngOnInit(): void {
    this.radioForm = this.fb.group({
      radio: [this.control.value]
    });

    this.radio.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        this.control.setValue(value);
      })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
