import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { BaseControlValueAccessor } from "@hs/shared/components/base-control-value-accessor";
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgImageSliderComponent } from "ng-image-slider";

@Component({
  selector: 'hs-photos-gallery',
  templateUrl: './photos-gallery.component.html',
  styleUrls: ['./photos-gallery.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PhotosGalleryComponent,
      multi: true,
    },
  ]
})
export class PhotosGalleryComponent extends BaseControlValueAccessor implements OnInit {
  public files = [];
  public currentIndex: number = 0;
  @ViewChild('gallery') slider: NgImageSliderComponent;

  constructor(
    @Optional() controlContainer: ControlContainer,
  ) {
    super(controlContainer)
  }

  imageObject: Array<object> = [];

  ngOnInit(): void {
    this.files = this.control.value;
    this.loadFiles();

    if (!this.files.length) {
      this.imageObject.push({
        thumbImage: '/assets/images/house-placeholder.png',
        title: ''
      });
    }
  }

  loadFiles () {
    this.files.forEach( photo => {
      if (!photo) {
        return;
      }

      if (photo.url) {
        this.imageObject.push({
          thumbImage: photo.url,
          title: photo.description
        });
        return;
      }

      if (photo.file.type.includes('image')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageObject.push({
            thumbImage: e.target.result,
            title: photo.description
          });
        }
        reader.readAsDataURL(photo.file);
      }
    });
  }

  prevImageClick() {
    this.currentIndex = this.currentIndex - 1 >= 0 ? this.currentIndex - 1 : 0;
    this.slider.prev();
  }

  nextImageClick() {
    this.currentIndex = this.currentIndex + 1 < this.imageObject.length ? this.currentIndex + 1 : this.imageObject.length;
    this.slider.next();
  }

}
