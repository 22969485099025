<mat-dialog-content>
  <div class="modal-content">
    <div class="close-modal" mat-dialog-close>
      <img alt="" src="../../../../assets/images/close.svg">
    </div>
    <h2 class="hs-title-bold modal-title">{{data?.message || ''}}</h2>
    <p class="description" *ngIf="data?.description">{{data?.description || ''}}</p>
    <div *ngIf="data?.emails" class="emails-wrapper">
      <span class="email" *ngFor="let email of data?.emails">{{email}}</span>
    </div>
    <button hsButton hsType="primary" [mat-dialog-close]="true" class="ok-button hs-btn-default">{{data?.buttonName || 'OK'}}</button>
  </div>
</mat-dialog-content>
