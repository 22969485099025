import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../../base-control-value-accessor';
import {newArray} from "@angular/compiler/src/util";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'hs-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DocumentsUploadComponent,
      multi: true,
    },
  ]
})
export class DocumentsUploadComponent extends BaseControlValueAccessor implements OnInit {
  @Input() placeholder = '';
  @Input() accept = '*';
  @Input() showName = false;
  @Input() previewCount: number = 3;
  @Output() onImageSelect = new EventEmitter<number>();

  selectedImage: number;
  files = [];
  imageSrc: any[] = [];

  constructor(
    @Optional() controlContainer: ControlContainer,
  ) {
    super(controlContainer)
  }

  ngOnInit(): void {
    this.files = [
      ...this.control.value,
      ...Array(this.previewCount - this.control.value.length).fill(null )
    ];
    this.loadFiles()

  }

  loadFiles () {
    this.files.forEach( async photo => {
      if (!photo) {
        return;
      }

      if (photo.url && (photo.extension === 'jpg' || photo.extension === 'png')) {
        this.imageSrc.push(photo.url);
        return;
      }

      if (photo.file && photo.file.type.includes('image')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageSrc.push(e.target.result);
        }
        reader.readAsDataURL(photo.file);
      } else {
        this.imageSrc.push(null);
      }
    });
  }

  onSelect(event) {
    event.addedFiles.forEach((file: File) => {
      if (file.type.includes('image')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageSrc.push(e.target.result);
        }
        reader.readAsDataURL(file);
      } else {
        this.imageSrc.push(null);
      }
    });
    this.pushImages(event.addedFiles)
    const uploadedFiles = this.files.filter(f => !!f)
    this.control.setValue(uploadedFiles);
  }

  pushImages(addedFiles): void {
    const uploadedFiles = this.files.filter(f => !!f);
    const files = [];
    addedFiles.forEach(file => {
      files.push({
        file,
        description: ''
      })
    })
    this.files.splice(uploadedFiles.length, addedFiles.length, ...files)
  }

  onPreviewClick(index: number): void {
    if (!this.files[index]) {
      return;
    }

    this.selectedImage = index;
    this.onImageSelect.next(index)
  }

  onRemove(event) {
    const index = this.files.indexOf( event );
    this.files.splice( index, 1 );
    this.files.push( null );
    this.imageSrc.splice( index, 1 );
    this.control.setValue( this.files );
    this.onImageSelect.next( null )
  }
}
