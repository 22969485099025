<mat-dialog-content>
  <div class="modal-content">
    <div class="close-modal" mat-dialog-close>
      <img alt="" src="../../../../assets/images/close.svg">
    </div>
    <h2 class="title">Select photo and add describtion for selected photo.</h2>
    <div class="images" *ngIf="data?.images">
      <div
        *ngFor="let image of data.images; let i = index"
        class="image"
        [class.active]="i === selectedImage"
        (click)="onImageClick(i)">
        <img [src]="image" alt="">
        <div class="description" *ngIf="data?.files[i] && data?.files[i].description">{{data?.files[i].description}}</div>
      </div>
    </div>

    <label for="description" class="label"></label>
    <textarea
      id="description"
      [rows]="6"
      [value]="files[selectedImage]?.description"
      (blur)="onInputBlur($event)"
      class="input"
      placeholder="Add description for selected photo here">
    </textarea>

    <div class="buttons">
      <button hsButton hsType="primary" class="hs-btn-default" [mat-dialog-close]="files">Save</button>
      <button hsButton hsType="default" class="hs-btn-default cancel" mat-dialog-close>Cancel</button>
    </div>
  </div>
</mat-dialog-content>
