import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ResizeService } from "@hs/core/services/resize.service";

@Component({
  selector: 'hs-scroll-navigator',
  templateUrl: './scroll-navigator.component.html',
  styleUrls: ['./scroll-navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollNavigatorComponent implements AfterContentChecked, OnDestroy {
  public pageHeight: string = '20px';
  public pagePosition: string = '0';
  public scrollContent: HTMLElement;
  public content: HTMLElement;

  constructor(
    private _resizeService: ResizeService,
    private _cdRef: ChangeDetectorRef
  ) {}

  ngAfterContentChecked(): void {
    if (this.content && this.content.offsetHeight) {
      return;
    }

    this.calculateSectionParameters();
    this.scrollContent = document.getElementById('scroll-content') as HTMLElement;
    this.content = document.getElementById('page') as HTMLElement;
    if (this.content && this.content.offsetHeight) {
      this.scrollContent.addEventListener('scroll', () => {
        this.calculateSectionParameters();
      });
    }
    if (this.content) {
      this._resizeService.addResizeEventListener(this.content, () => {
        this.calculateSectionParameters();
      })
    }
  }

  calculateSectionParameters(): void {
    const wrapper = document.querySelector('.page-navigation-wrapper') as HTMLElement;
    const footer = document.getElementById('footer') as HTMLElement;
    const footerHeight = footer?.offsetHeight || 0;
    const contentHeight = this.content?.offsetHeight + footerHeight;
    const clientHeight = this.scrollContent?.offsetHeight;
    const scale = contentHeight / wrapper?.offsetHeight;
    const scrollTop = this.scrollContent?.scrollTop || 0;
    const pageHeight = clientHeight / scale > wrapper?.offsetHeight
      ? wrapper?.offsetHeight
      : clientHeight / scale;
    this.pageHeight = `${Math.ceil(pageHeight)}px`;
    const pagePosition = Math.ceil(scrollTop / scale);
    this.pagePosition = pagePosition > wrapper?.offsetHeight - pageHeight
      ? `${wrapper?.offsetHeight - pageHeight}px`
      : `${pagePosition}px`;
    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.scrollContent.removeEventListener('scroll', this.calculateSectionParameters);
    this._resizeService.removeResizeEventListener(this.content)
  }
}
