<div class="info-section">
  <p class="label">Special offer</p>
  <h2 class="title">{{data?.title}}</h2>
</div>
<div class="info-section">
  <div class="properties">
    <div class="property">
      <img alt="" src="../../../../assets/images/dimension.svg" style="width: 20px; height: 20px">
      {{data?.dimension || 0}} M
    </div>
    <div class="property">
      <img alt="" src="../../../../assets/images/bedroom.svg" style="width: 20px; height: 20px">
      {{data?.numberOfBeds || 0}}
    </div>
    <div class="property">
      <img alt="" src="../../../../assets/images/bathroom.svg" style="width: 20px; height: 20px">
      {{data?.bathroomNumber || 0}}
    </div>
  </div>
</div>
<div class="info-section" *ngIf="data?.propertyDescription">
  <p class="description">
    {{data?.propertyDescription}}
  </p>
</div>
<div class="info-section" *ngIf="data?.features">
  <h3 class="section-title">Amenities</h3>
  <div class="amenities" [class.expanded]="isFeaturesExpanded">
    <div class="feature" *ngFor="let feature of data?.features">
      <img [src]="'assets/images/properties/' + feature + '.svg'" alt="">
      {{getPropertyLabel(feature)}}
    </div>
  </div>

  <div class="see-all" *ngIf="data?.features.length > 3 && !isFeaturesExpanded" (click)="toggleFeatures()">
    see all ({{data?.features.length}})
  </div>
</div>
<div class="info-section calendar">
  <h3 class="section-title">Availability</h3>
  <hs-calendar
    [fromDate]="fromDate"
    [toDate]="toDate"
  ></hs-calendar>
</div>
