import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProfile } from '@hs/models';
import { UserEntity } from "@hs/core/interfaces/user.interface";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { finalize, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _dbUser: UserEntity;
  private _dbUserProfile: UserEntity;
  private _dbUserEmail: string;
  private _dbUserToken: string;
  private _dbUserVerifyToken: string;
  public userSubject = new Subject<boolean>();


  userChange(isChanged: boolean) {
    this.userSubject.next(isChanged);
  }

  constructor(
    private _http: HttpClient,
    private _router: Router
  ) {
  }

  getCurrentUserProfile(): void {
    this.getUserProfile()
      .pipe(
        switchMap( (userProfile) => {
          this.userProfile = userProfile;
          return userProfile.toString();
        } ),
        finalize( () => {} )
      )
      .subscribe({
        next: () => {},
        error: err => console.error(err)
      });
  }

  getUserProfile() {
    return this._http.get<UserEntity>('/api/user-profile');
  }

  getNotificationsSettings() {
    return this._http.get<any>('/api/notification-setting');
  }

  inviteUsers(emails: string[]) {
    return this._http.post<{ data: any }>('/api/invite', emails);

  }

  updateNotificationsSettings(params) {
    const url = this._router.createUrlTree([ '/api/notification-setting' ], { queryParams: params }).toString();
    return this._http.put<{ data: string }>(url, {});
  }

  updateUserProfile(user: UserEntity) {
    const url = this._router.createUrlTree([ '/api/user-profile' ], {}).toString();
    return this._http.put<{ data: string }>(url, user);
  }

  updateProfilePhoto(formData: FormData) {
    return this._http.put<{ data: any }>('/api/user-profile/updateImage', formData);
  }

  changeUserPassword(currentPassword: string, newPassword: string) {
    const url = this._router.createUrlTree([ '/api/user-profile/change-password' ], { queryParams: { currentPassword, newPassword }}).toString();
    return this._http.post<{ data: boolean }>(url, {});
  }

  isEmailExists(email: string) {
    const url = this._router.createUrlTree([ '/api/email-exists' ], { queryParams: { value: email } }).toString();
    return this._http.stopAddingAuthorizationToken().post<{ data: boolean }>(url, {});
  }

  get databaseUser(): UserEntity {
    return this._dbUser
  }

  set databaseUser(newUser) {
    this._dbUser = newUser;
  }

  get userProfile(): UserEntity {
    return this._dbUserProfile
  }

  set userProfile(newUrl) {
    this._dbUserProfile = newUrl;
  }

  get databaseUserEmail(): string {
    return this._dbUserEmail
  }

  set databaseUserEmail(newEmail) {
    this._dbUserEmail = newEmail;
  }

  get databaseUserToken(): string {
    return this._dbUserToken
  }

  set databaseUserToken(newToken) {
    this._dbUserToken = newToken;
  }

  get databaseUserVerifyToken(): string {
    return this._dbUserVerifyToken
  }

  set databaseUserVerifyToken(newToken) {
    this._dbUserVerifyToken = newToken;
  }
}
