import { Injectable } from '@angular/core';
import { SearchDTO } from "@hs/core/interfaces/search.interface";
import { House } from "@hs/models";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class SearchService {
  public moreFiltersLabel = 'More';
  public moreFiltersIsFilled = false;
  public onFiltersChange = new Subject<void>();
  private _searchDTO: SearchDTO;
  private _resultsCount: number = 0;

  constructor(
    private _http: HttpClient
  ) { }

  public setDefaultSearchDTO(): void {
    this.searchDTO = {}
  }

  getSearchCount(body: SearchDTO) {
    this._http.post<House[]>('/api/house/search', body, { observe: 'response' })
      .subscribe(res => {
        this.resultsCount = +res.headers.get('Total-Elements');
      })
  }

  get resultsCount() {
    return this._resultsCount;
  }

  set resultsCount(resultsCount) {
    this._resultsCount = resultsCount;
  }
  get searchDTO() {
    return this._searchDTO;
  }

  set searchDTO(searchDTO) {
    this._searchDTO = searchDTO;
  }
}
