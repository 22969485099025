import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { NumberRange, SearchField } from "@hs/core/interfaces/search.interface";
import { SearchService } from "@hs/core/services/data/search.service";

@Component({
  selector: 'hs-guests-filter',
  templateUrl: './guests-filter.component.html',
  styleUrls: ['./guests-filter.component.scss']
})
export class GuestsFilterComponent implements OnInit, OnChanges {
  @Input() filter: TemplateRef<any>;
  @Input() activeFilter: string;
  @Output() changeFilters = new EventEmitter<SearchField>();
  public isFilled: boolean;
  public name: string = 'guestNumberRange';
  public defaultLabel: string = 'Guests';
  public label: string;
  public guestsCount: number = 0;
  public guestNumberRange: NumberRange;
  timeout;

  constructor(
    private _searchService: SearchService
  ) { }

  get filters () {
    return this._searchService.searchDTO;
  }

  ngOnInit(): void {
    this.label = this.defaultLabel;
    this._searchService.onFiltersChange.subscribe(() => {
      if (!this.filters[this.name]) {
        this.guestsCount = 0;
        delete this.guestNumberRange;
      } else {
        this.guestsCount = this.filters[this.name].min;
        this.guestNumberRange = {
          min: this.guestsCount ,
          max: 100
        }
      }

      this.label = this.filters[this.name] && this.filters[this.name].min
        ? this.filters[this.name].min > 1
          ? `${this.filters[this.name].min} guests`
          : `${this.filters[this.name].min} guest`
        : this.defaultLabel;
      this.isFilled = this.label !== this.defaultLabel;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeFilter
      && changes.activeFilter.previousValue === this.name
      && changes.activeFilter.currentValue !== this.name
      && document.scrollingElement.clientWidth <= 767) {
      this._searchService.searchDTO = {
        ...this.filters,
        [this.name]: this.guestNumberRange || null
      }
      this._searchService.onFiltersChange.next();
      this._searchService.getSearchCount(this._searchService.searchDTO);
    }
  }

  onPlus() {
    if (this.guestsCount < 100) {
      this.guestsCount = this.guestsCount + 1;
      this.guestNumberRange = {
        min: this.guestsCount,
        max: 100
      }
    }
  }

  onMinus() {
    if (this.guestsCount > 0) {
      this.guestsCount = this.guestsCount - 1;
      this.guestNumberRange = {
        min: this.guestsCount,
        max: 100
      }
    }
  }

  public clearFilter(): void {
    this.guestsCount = 0;
    delete this.guestNumberRange;

    this._searchService.searchDTO = {
      ...this.filters,
      [this.name]: null
    }
    this._searchService.onFiltersChange.next();
    this.changeFilters.next();
  }

  public applyFilter(): void {
    this._searchService.searchDTO = {
      ...this.filters,
      [this.name]: this.guestNumberRange || null
    }
    this._searchService.onFiltersChange.next();
    this.changeFilters.next();
  }
}
