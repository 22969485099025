import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GUEST_EXPECT, House, HOUSE_INTERIORS_STYLES, HOUSE_TYPES } from '@hs/models';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HouseService {
  private _formGroup: FormGroup;
  private _house: House | any;
  private _places = {
    allPlaces: [],
    myPlaces: [],
    givenAccessPlaces: [],
    uncompletedPlaces: []
  };

  constructor(
    private _fb: FormBuilder,
    private _http: HttpClient,
    private _router: Router
  ) { }

  getHouseDetails(id: string) {
    return this._http.get<House>(`/api/house/${id}`, {});
  }

  getPropertyTypes() {
    return this._http.get<any>('/api/house/type/statistics', {});
  }

  deleteHouse(id: string) {
    return this._http.delete(`/api/house/${id}`, {});
  }

  getAllPlaces() {
    const url = this._router.createUrlTree([ '/api/house' ], { queryParams: { withGivenAccess: true } }).toString();
    return this._http.get<House[]>(url, {});
  }

  getMyPlaces() {
    const url = this._router.createUrlTree([ '/api/house' ], { queryParams: { withGivenAccess: false } }).toString();
    return this._http.get<House[]>(url, {});
  }

  getGivenAccessPlaces() {
    const url = this._router.createUrlTree([ '/api/house/by-permission' ]).toString();
    return this._http.get<House[]>(url, {});
  }

  getUncompletedPlaces() {
    const url = this._router.createUrlTree([ '/api/temp-data/search' ]).toString();
    return this._http.post<any[]>(url, {
      "dataGroup": "PROPERTY"
    });
  }

  get formGroup(): FormGroup {
    return this._formGroup
  }

  set formGroup(formGroup) {
    this._formGroup = formGroup;
  }

  get house(): House | any {
    return this._house
  }

  set house(house) {
    this._house = house;
  }

  get places() {
    return this._places;
  }

  set places(newPlaces) {
    this._places = newPlaces;
  }

  createFormGroup(house?: House):void {
    this.formGroup = this._fb.group( {
      id: [ house?.id || null ],
      tempDataId: [ house?.tempDataId || null ],
      houseType: [ house?.houseType || null ],
      houseInteriorStyle: [ house?.houseInteriorStyle || null ],
      guestExpect: [  house?.guestNumber || GUEST_EXPECT.ENTIRE ],
      spaceDedicated: [ house?.spaceDedicated || false ],

      dimension: [ house?.dimension || null ],
      guestNumber: [ house?.guestNumber || null ],
      bedroomNumber: [ house?.bedroomNumber || null ],
      bathroomNumber: [ house?.bathroomNumber || null ],

      description: [ house?.description || '' ],

      images: [ house?.images || [] ],
      imagesDelete: [ [] ],
      ownershipDocuments: [ house?.ownershipDocuments || [] ],

      features: [ house?.features || [] ],

      rules: [ house?.rules || '' ],

      title: [ house?.title || null ],
      property_description: [ house?.description || null ],

      availabilityRange: [ [ house?.availableFrom || new Date(), house?.availableTo || new Date() ] ],

      roomInfoList: [ house?.roomInfoList || [
        {
          name: 'Common spaces',
          roomObjectList: []
        }
      ] ],

      location: this._fb.group( {
        apartmentNumber: [ house?.location.apartmentNumber || '' ],
        address: [ house?.location.address || null],
        city: [ house?.location.city || null],
        country: [ house?.location.country || null],
        latitude: [ house?.location.latitude || 0 ],
        longitude: [ house?.location.longitude || 0 ],
        postalCode: [ house?.location.postalCode || null],
        state: [ house?.location.state || null],
      } )
    } )
  }

  searchHouse(body: any = {}, sort: string = '') {
    return this._http.post<House[]>(`/api/house/search${sort}`, body, {
      observe: 'response'
    });
  }

  createHouse(formData: FormData) {
    return this._http.post('/api/house', formData);
  }

  saveUncompletedHouse(data) {
    return this._http.post('/api/temp-data', data);
  }

  deleteTempData(id) {
    return this._http.delete(`/api/temp-data/${id}`);
  }

  updateHouse(formData: FormData) {
    return this._http.put('/api/house', formData);
  }
}
