import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { HOUSE_TYPES, SelectOption } from "@hs/models";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { SearchService } from "@hs/core/services/data/search.service";

@Component({
  selector: 'hs-type-filter',
  templateUrl: './type-filter.component.html',
  styleUrls: ['./type-filter.component.scss']
})
export class TypeFilterComponent implements OnInit, OnChanges {
  @Input() filter: TemplateRef<any>;
  @Input() activeFilter: string;
  @Output() changeFilters = new EventEmitter<void>();
  public isFilled: boolean;
  public name: string = 'houseTypes';
  public defaultLabel: string = 'Type';
  public label: string;
  public formGroup: FormGroup;

  public options: SelectOption[] = [
    { label: 'Apartment', value: HOUSE_TYPES.APARTMENT },
    { label: 'Detached house', value: HOUSE_TYPES.DETACHED_HOUSE },
    { label: 'Cottage', value: HOUSE_TYPES.COTTAGE },
    { label: 'Mansion', value: HOUSE_TYPES.MANSION },
    { label: 'Villa', value: HOUSE_TYPES.VILLA },
    { label: 'Manor', value: HOUSE_TYPES.MANOR },
    { label: 'Other', value: HOUSE_TYPES.HOUSE },
  ]

  get types() {
    return this.formGroup.get('types') as FormControl;
  }

  get filters () {
    return this._searchService.searchDTO;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _searchService: SearchService,
    private _cdRef: ChangeDetectorRef
  ) {
    this.formGroup = this._formBuilder.group({
      types: [[]]
    })
  }

  ngOnInit(): void {
    this.label = this.defaultLabel;
    this._searchService.onFiltersChange.subscribe(() => {
      if(!this.filters[this.name]) {
        this.types.setValue([]);
      } else {
        this.types.setValue(this.filters[this.name]);
      }
      this.label = this.filters[this.name] && this.filters[this.name].length
        ? this.filters[this.name].length === 1
          ? this.options.find(item => item.value === this.filters[this.name][0])?.label
          : `${this.filters[this.name].length} Types`
        : this.defaultLabel;
      this.isFilled = this.label !== this.defaultLabel;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeFilter
      && changes.activeFilter.previousValue === this.name
      && changes.activeFilter.currentValue !== this.name
      && document.scrollingElement.clientWidth <= 767) {
      this._searchService.searchDTO = {
        ...this.filters,
        [this.name]: this.types.value || null
      }
      this._searchService.onFiltersChange.next();
      this._searchService.getSearchCount(this._searchService.searchDTO);
    }
  }

  public clearFilter(): void {
    this.types.setValue([]);
    this._searchService.searchDTO = {
      ...this.filters,
      [this.name]: null
    }
    this._searchService.onFiltersChange.next();
    this.changeFilters.next();
  }

  public applyFilter(): void {
    this._searchService.searchDTO = {
      ...this.filters,
      [this.name]: this.types.value || null
    }
    this._searchService.onFiltersChange.next();
    this.changeFilters.next();
  }
}
