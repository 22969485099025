<div class="gallery-wrapper">
  <ng-image-slider
    #gallery
    class="slider"
    [imageSize]="{width: '100%', height: '300px'}"
    [imagePopup]="false"
    [showArrow]="false"
    [images]="imageObject">
  </ng-image-slider>
  <div
    class="nav prev"
    [class.disabled]="currentIndex === 0"
    *ngIf="imageObject.length > 1"
    (click)="prevImageClick()">
    <img src="assets/images/arrow-right.svg" alt="">
  </div>
  <div
    class="nav next"
    [class.disabled]="currentIndex === imageObject.length"
    *ngIf="imageObject.length > 1"
    (click)="nextImageClick()">
    <img src="assets/images/arrow-right.svg" alt="">
  </div>
</div>
