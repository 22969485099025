<ng-container
  *ngIf="filter"
  [ngTemplateOutlet]="filter"
  [ngTemplateOutletContext]="{
    content: content,
    name: name,
    label: label,
    isFilled: isFilled
  }"
></ng-container>

<ng-template #content>
  <hs-more-filter-content></hs-more-filter-content>
</ng-template>
