import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '@hs/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseControlValueAccessor } from '../../base-control-value-accessor';

@Component({
  selector: 'hs-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectGroupComponent,
      multi: true,
    },
  ]
})
export class SelectGroupComponent extends BaseControlValueAccessor implements OnInit, OnDestroy {

  @Input() options: SelectOption[] = [];
  @Input() colsCount: number = 2;
  gridTemplateCols: string;
  formSelect: FormGroup;
  get formOptions() {
    return this.formSelect.get('options') as FormArray;
  }

  private destroyed$ = new Subject<void>();

  constructor(
    @Optional() controlContainer: ControlContainer,
    private fb: FormBuilder
  ) {
    super(controlContainer)
  }

  ngOnInit(): void {
    this.formSelect = this.fb.group({
      options: this.fb.array(this.options.map(o => this.control.value.includes(o.value)))
    });

    this.gridTemplateCols = '';
    for (let i = 0; i < this.colsCount; i++) {
      this.gridTemplateCols = this.gridTemplateCols + '1fr '
    }

    this.formOptions.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        const selectedOptions = this.options.map(i => i.value).filter((_, i) => value[i]);
        this.control.setValue(selectedOptions);
      })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
