import { Injectable } from '@angular/core';
import { UserProfile } from '@hs/models';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserEntity } from "@hs/core/interfaces/user.interface";

@Injectable({
  providedIn: 'root'
})
export class AppContext {
  user$: Observable<UserEntity>

  user: UserEntity;
  private userSubject = new ReplaySubject<UserEntity>(1);

  constructor() {
    this.user$ = this.userSubject.asObservable().pipe(first());
  }

  setUser(profile: UserEntity) {
    this.user = profile;
    this.userSubject.next(profile);
  }
}
