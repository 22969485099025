import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GUEST_EXPECT, RadioItem, SelectOption } from "@hs/models";
import { SearchService } from "@hs/core/services/data/search.service";

@Component({
  selector: 'hs-more-filter-content',
  templateUrl: './more-filter-content.component.html',
  styleUrls: ['./more-filter-content.component.scss']
})
export class MoreFilterContentComponent implements OnInit {
  public formGroup: FormGroup;
  isFilled: boolean;
  label: string;
  defaultLabel: string = 'More';
  filtersCount: number = 0;

  public filtersNames =  ['spaceType', 'spaceDedicated', 'bedroomNumberRange', 'bathroomNumberRange', 'features'];
  timeout;


  constructor(
    private _formBuilder: FormBuilder,
    private _searchService: SearchService
  ) {
    this.formGroup = this._formBuilder.group({
      spaceType: [],
      spaceDedicated: [],
      bedroomNumberRange: [0],
      bathroomNumberRange: [0],
      features: [[]]
    })
  }

  guestExpects: RadioItem[] = [
    { value: GUEST_EXPECT.ENTIRE, label: 'Entire place', description: 'Guests have the whole place to themselves. This usually includes a bedroom, a bathroom, and a kitchen, parking place etc.' },
    { value: GUEST_EXPECT.PRIVATE, label: 'Private room (or rooms)', description: 'Guests have their own private room for sleeping. Other areas could be shared.' },
  ];

  dedicates: RadioItem[] = [
    { value: true, label: 'Yes, i need a dedicated space' },
    { value: false, label: 'No, owner keep personal belongings' },
  ];

  additionalOptions: SelectOption[] = [
    { value: 'wifi', label: 'Wi-Fi' },
    { value: 'satelite', label: 'Satelite' },
    { value: 'hifi', label: 'Hi-Fi Sound' },
    { value: 'home-cinema', label: 'Home Cinema' },
    { value: 'working-space', label: 'Working space' },
    { value: 'library', label: 'Library' },
    { value: 'fireplace', label: 'Fireplace' },
    { value: 'louge', label: 'Lounge' },
  ];

  get filters() {
    return this._searchService.searchDTO;
  }

  ngOnInit(): void {
    this._searchService.onFiltersChange.subscribe(() => {
      this.updateFiltersForm();
      this._searchService.moreFiltersLabel = this.filtersCount > 0 ? `${this.filtersCount} More` : this.defaultLabel;
      this._searchService.moreFiltersIsFilled = this._searchService.moreFiltersLabel!== this.defaultLabel;
    })

    this.formGroup.valueChanges.subscribe(form => {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }
      const filters = {};
      Object.keys(form).forEach(field => {
        if (field === 'bedroomNumberRange') {
          filters[field] = {
            min: form[field],
            max: 100
          }
        } else if (field === 'bathroomNumberRange') {
          filters[field] = {
            min: form[field],
            max: 100
          }
        } else {
          filters[field] = form[field];
        }
      })
      this.timeout = setTimeout(() => {
        this._searchService.searchDTO = {
          ...this._searchService.searchDTO,
          ...filters
        }
        console.count('search')

        this._searchService.getSearchCount(this._searchService.searchDTO);
      }, 1000)
    })
    this.updateFiltersForm();
  }

  updateFiltersForm() {
    this.filtersCount = 0;
    this.filtersNames.forEach(name => {
      const control = this.formGroup.get(name) as FormControl;
      if (name === 'bedroomNumberRange' || name === 'bathroomNumberRange') {
        control.setValue(this.filters[name]?.min || 0);
        this.filtersCount = this.filters[name]?.min ?  this.filtersCount + 1 :  this.filtersCount;
      } else if (typeof this.filters[name] === 'boolean') {
        control.setValue(!!this.filters[name]);
        this.filtersCount = this.filtersCount + 1;
      } else if (name === 'features') {
        control.setValue(this.filters[name]?.length ? this.filters[name] : []);
        this.filtersCount = this.filters[name]?.length ? this.filtersCount + 1 : this.filtersCount;
      } else {
        control.setValue(this.filters[name] || null);
        this.filtersCount = this.filters[name] ? this.filtersCount + 1 : this.filtersCount;
      }
    })

  }

}
