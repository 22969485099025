import { Provider } from '@angular/core';
import { Language } from '@hs/core/constants/languages';
import { TranslocoConfig, TRANSLOCO_CONFIG, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { TranslocoLoaderService } from './transloco-loader.service';


export const TRANSLOCO_CONFIGS: Provider[] = [
  {
    provide: TRANSLOCO_CONFIG,
    useValue: {
      reRenderOnLangChange: true,
      availableLangs: [ Language.English ],
      prodMode: environment.production,
      defaultLang: Language.English,
      fallbackLang: Language.English,
      flatten: {
        aot: environment.production,
      },
    } as TranslocoConfig
  },
  {
    provide: TRANSLOCO_LOADER,
    useClass: TranslocoLoaderService
  }
];
