<div class="filters-container">
  <div class="filters">
    <hs-dates-filter class="filter" [filter]="filter" (changeFilters)="changeFilters()" [activeFilter]="activePopup"></hs-dates-filter>
    <hs-guests-filter class="filter" [filter]="filter" (changeFilters)="changeFilters()" [activeFilter]="activePopup"></hs-guests-filter>
    <hs-area-filter class="filter" [filter]="filter" (changeFilters)="changeFilters()" [activeFilter]="activePopup"></hs-area-filter>
    <hs-type-filter class="filter" [filter]="filter" (changeFilters)="changeFilters()" [activeFilter]="activePopup"></hs-type-filter>
    <div
      class="filter-label more-desktop"
      [class.active]="isMoreFiltersOpened"
      [class.filled]="moreFiltersIsFilled"
      (click)="onMoreFiltersClick()">
      {{moreFiltersLabel}}
    </div>
    <hs-more-filter class="filter more-mobile" [filter]="filter" (changeFilters)="changeFilters()" [filters]="filters"></hs-more-filter>

    <button hsButton hsType="default" class="hs-btn-default clear-all" *ngIf="filters !== defaultFilters" (click)="clearAll()">
      Clear all
    </button>
  </div>
</div>


<ng-template
  #filter
  let-content="content"
  let-label="label"
  let-name="name"
  let-isFilled="isFilled"
  let-isDimension="isDimension">
  <div class="filter-label-wrapper">
    <div
      class="filter-label"
      [class.active]="activePopup === name"
      [class.filled]="isFilled"
      (click)="onLabelClick(name)">
      {{label}}<sup *ngIf="isDimension">2</sup>
    </div>

    <ng-container *ngIf="activePopup === name">
      <div class="filter-popup">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>
      <div class="substrate" (click)="onSubstrateClick()"></div>
    </ng-container>
  </div>

</ng-template>
