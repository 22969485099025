<div class="hs-page-container">
  <hs-header></hs-header>
  <div class="hs-wrapper not-found-wrapper">
    <div class="not-found-container info">
      <h2 class="not-found-title">OOPS!</h2>
      <h2 class="not-found-title">NOTHING FOUND</h2>
      <button hsButton hsType="primary" class="hs-btn-default go-home-button" routerLink="/">Go to home</button>
    </div>
    <div class="not-found-container">
      <img class="image" src="assets/images/not-found.svg" alt="">
    </div>
  </div>
  <hs-footer></hs-footer>
</div>
