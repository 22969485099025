<div class="hs-header-container {{customClass}}" [class.without-shadow]="hideShadow">
  <div class="hs-wrapper hs-header-wrapper">
    <hs-logo></hs-logo>
    <div class="header">
      <div class="header-content">
        <div class="header-row">
          <div class="content">
            <ng-content></ng-content>
          </div>
          <div class="menu-container">
            <div class="menus">
              <div class="menu" routerLink="/">Home</div>
              <div class="menu" routerLink="/places">My places</div>
              <div class="menu" ngbTooltip="In progress...">Saved</div>
              <div class="menu" ngbTooltip="In progress...">Messages</div>
              <div class="menu" routerLink="/add-my-property/">Add new house</div>
            </div>
          </div>
        </div>
        <div class="header-row filters" *ngIf="filters">
          <ng-container [ngTemplateOutlet]="filters"></ng-container>
        </div>
      </div>
      <hs-user-avatar></hs-user-avatar>
    </div>
  </div>
</div>

