import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from "rxjs/operators";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { SearchService } from "@hs/core/services/data/search.service";

@Component({
  selector: 'hs-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  public clearAll = new Subject<void>();
  private _destroyed$ = new Subject<void>();

  path: string;
  isOpenMenu = false;
  isFiltersActive = false;
  timeout;

  constructor(
    private _searchService: SearchService,
    private _router: Router
  ) { }

  get filters() {
    return this._searchService.searchDTO;
  }

  get searchResultsCount () {
    return this._searchService.resultsCount;
  }

  get isFiltersNotEmpty () {
    return this._searchService.searchDTO
      ? Object.keys(this._searchService.searchDTO).length
      : false
  }

  ngOnInit(): void {
    this.path = this._router['url'].split('?')[0]
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._destroyed$)
      )
      .subscribe(route => {
        this.path = route?.['url'].split('?')[0]
        const menu = document.getElementById('mobile-menu') as HTMLElement;
        if (menu && menu.classList.contains('mobile-hide')) {
          menu.classList.remove('mobile-hide');
        }
      })
  }

  public clearAllClick(): void {
    this.isFiltersActive = false;
    this.clearAll.next()
  }

  public toggleMenu() {
    this.isOpenMenu = !this.isOpenMenu;
  }

  public toggleFilters(saveOnClose?) {
    this.isFiltersActive = !this.isFiltersActive;

    if (saveOnClose && !this.isFiltersActive) {
      const filters = [];
      Object.keys(this.filters).forEach(filterName => {
        if (this.filters[filterName]) {
          filters.push(`${filterName}=${JSON.stringify(this.filters[filterName])}`);
        }
      });
      this._router.navigateByUrl(`/places/search?${filters.join('&')}`);
    }
  }

  public onInputChange(event) {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this._searchService.searchDTO = {
        ...this._searchService.searchDTO,
        location: {
          city: event.target.value
        }
      }
      this._searchService.onFiltersChange.next();
      const filters = [];
      Object.keys(this.filters).forEach(filterName => {
        if (this.filters[filterName]) {
          filters.push(`${filterName}=${JSON.stringify(this.filters[filterName])}`);
        }
      });
      this._router.navigateByUrl(`/places/search?${filters.join('&')}`)
    }, 1000);
  }


  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
