import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {ControlContainer, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import { BaseControlValueAccessor } from '../../base-control-value-accessor';

@Component({
  selector: 'hs-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextComponent,
      multi: true,
    },
  ]
})
export class InputTextComponent extends BaseControlValueAccessor implements OnInit {
  @Input() label = '';
  @Input() maxWidth = '280px';
  @Input() placeholder = '';

  @Input() type: 'input' | 'textarea' = 'input';
  @Input() rows = 1;
  @Output() onBlur = new EventEmitter();

  control: FormControl;

  constructor(
    @Optional() controlContainer: ControlContainer,
  ) {
    super(controlContainer)
  }

  ngOnInit() {
  }

  onInputBlur(): void {
    if (!this.onBlur) {
      return;
    }
    this.onBlur.next()
  }

}
