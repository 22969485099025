import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BaseControlValueAccessor } from '../../base-control-value-accessor';

@Component({
  selector: 'hs-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateRangeComponent,
      multi: true,
    },
  ]
})
export class DateRangeComponent extends BaseControlValueAccessor implements OnInit {
  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate | null = null;
  currentDate: NgbDate;

  hoveredDate: NgbDate | null = null;

  constructor(
    @Optional() controlContainer: ControlContainer,
  ) {
    super(controlContainer)
  }

  ngOnInit(): void {
    const current = new Date();
    this.currentDate = { day: current.getDate(), month: current.getMonth() + 1, year: current.getFullYear() } as NgbDate;
    if (this.control && this.control.value && Array.isArray(this.control.value) && this.control.value.length === 2) {
      const [ fromTimestamp, toTimestamp ] = this.control.value as [Date, Date];
      const from = new Date(fromTimestamp);
      const to = new Date(toTimestamp);
      this.fromDate = { day: from.getDate(), month: from.getMonth() + 1, year: from.getFullYear() } as NgbDate;
      this.toDate = { day: to.getDate(), month: to.getMonth() + 1, year: to.getFullYear() } as NgbDate;
    }
  }

  onDateSelection(date: NgbDate) {
    if (date.before(this.currentDate)) {
      return;
    }
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate && this.toDate) {
      this.control.setValue([
        new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
        new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
      ])
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

}
