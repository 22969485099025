import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslocoLoaderService implements TranslocoLoader {
  constructor(
    private http: HttpClient,
  ) {
  }

  getTranslation(lang: string, data?: TranslocoLoaderData): Observable<Translation> | Promise<Translation> {
    return this.http
      .disableApiPrefix()
      .get<Translation>(`/assets/i18n/${lang}.json`, {
      headers: { cache: 'no-cache' }
    });
  }
}

