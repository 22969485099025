<ng-container
  *ngIf="filter"
  [ngTemplateOutlet]="filter"
  [ngTemplateOutletContext]="{
    content: content,
    name: name,
    label: label,
    isFilled: isFilled
  }"
></ng-container>

<ng-template #content>
  <div class="datepicker-desktop">
    <ngb-datepicker
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="2"
      [navigation]="'arrows'"
      [dayTemplate]="day"
      outsideDays="hidden"
      class="hs-date-range hs-date-range-filter"
    ></ngb-datepicker>
  </div>
  <div class="datepicker-mobile">
    <ngb-datepicker
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="1"
      [navigation]="'arrows'"
      [dayTemplate]="day"
      outsideDays="hidden"
      class="hs-date-range hs-date-range-filter"
    ></ngb-datepicker>
  </div>
  <div class="filter-popup-footer">
    <button hsButton hsType="default" class="hs-btn-default clear" (click)="clearFilter()">Clear</button>
    <button hsButton hsType="primary" class="hs-btn-default" (click)="applyFilter()">Apply</button>
  </div>
</ng-template>

<ng-template #day let-date let-focused="focused">
    <span class="custom-day-filter"
          [class.focused]="isFocused(date)"
          [class.hovered]="isHovered(date)"
          [class.range]="isRange(date) || isInside(date)"
          [class.first-day]="isFirstDay(date)"
          [class.last-day]="isLastDay(date)"
          [class.single]="isSingle(date)"
          (mouseenter)="onDateHover(date)"
          (mouseleave)="onDateHover(null)">
      <span class="day">
        {{ date.day }}
      </span>
    </span>
</ng-template>
