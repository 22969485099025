<div class="hs-input-text-container">
  <p *ngIf="!!label">{{ label }}</p>
  <input
    (blur)="onInputBlur()"
    *ngIf="type === 'input'" class="input"
    [style.max-width]="maxWidth"
    [formControl]="control"
    [placeholder]="placeholder">
  <textarea
    (blur)="onInputBlur()"
    *ngIf="type === 'textarea'"
    [rows]="rows" class="input"
    [style.max-width]="maxWidth"
    [formControl]="control"
    [placeholder]="placeholder"></textarea>
</div>
