<div class="auth-footer-container">
  <div class="auth-footer-wrapper hs-container">
    <div class="copy-right">
      <img alt="" src="assets/images/logo.png" />
      <div>{{ 'auth.lions_copyright' | transloco }}</div>
    </div>
    <div class="privacy">
      <div>{{ 'auth.privacy_policy' | transloco }}</div>
      <div>{{ 'auth.terms_and_conditions' | transloco }}</div>
    </div>
    <div class="social-links">
      <div class="social">Fb</div>
      <div class="social">Ins</div>
      <div class="social">Tw</div>
    </div>
  </div>
</div>
