<div class="hs-select-container">
  <input [formControl]="control" style="display: none">
  <p *ngIf="!!label"><span *ngIf="isRequired" class="required">*</span> {{ label }}</p>
  <div ngbDropdown class="hs-select-control d-inline-block">
    <button ngbDropdownToggle id="hs-select" [class.is-error]="isInvalid()">
      <span class="hs-title">{{ valueName }}</span>
    </button>
    <div class="hs-select-menu" ngbDropdownMenu aria-labelledby="hs-select">
      <div class="hs-select-menu-item" ngbDropdownItem *ngFor="let option of options" (click)="setValue(option)">
        {{ option.label }}
      </div>
    </div>
    <div class='errors'>
      <ng-template *ngIf='isInvalid()' [ngTemplateOutlet]="error" [ngTemplateOutletContext]="{ errors: control!.errors }"></ng-template>
    </div>
    <ng-template #error let-errors="errors">
      <span *ngIf='errors["required"]'>Required field</span>
    </ng-template>

  </div>
</div>
