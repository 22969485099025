<div class="hs-title input-label" [class.is-icon]="leftIcon"><span *ngIf="isRequired" class="required">*</span> {{ label }}</div>
<div class="hs-input-container {{theme}}" [class.is-error]="isInvalid() || customError">
  <input
    (blur)="innerTouch()"
    (focus)="onInputFocus()"
    (input)="innerChange()"
    [class.invalid]="isInvalid()"
    [class.is-icon]="leftIcon"
    [class.is-icon-right]="rightIcon"
    [formControl]="control"
    [pattern]="pattern"
    [placeholder]=placeholder
    [type]='type'
    autocomplete='off'
  >
  <span *ngIf="leftIcon" class="icon">
    <ng-container [ngTemplateOutlet]="leftIcon"></ng-container>
  </span>
  <span *ngIf="rightIcon" class="icon-right">
    <ng-container [ngTemplateOutlet]="rightIcon"></ng-container>
    <span class="label">{{control.value || 0}} {{rightIconLabel || ''}}</span>
  </span>
</div>
<div class='errors'>
  <ng-template *ngIf='isInvalid()' [ngTemplateOutlet]="error" [ngTemplateOutletContext]="{ errors: ngControl.control!.errors }"></ng-template>
  <div *ngIf='customError'>{{customError}}</div>
</div>
<ng-content></ng-content>

<ng-template #error let-errors="errors">
  <span *ngIf='errors["required"]'>Required field</span>
  <span *ngIf='errors["email"]'>Email is not valid</span>
  <span *ngIf='errors["pattern"]'>Password is not valid</span>
</ng-template>
