import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageKey, StorageService } from '@hs/core/services';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthorizationTokenInterceptor implements HttpInterceptor {

  constructor(
    private storageService: StorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(environment.serverUrl)) {
      return this.getToken()
        .pipe(
          map((token) => request.headers.append('Authorization', `Bearer ${token}`)),
          map(newHeaders => request.clone({ headers: newHeaders })),
          switchMap(newRequest => next.handle(newRequest))
        )
    }

    return next.handle(request);
  }

  private getToken(): Observable<string> {
    return this.storageService.get<string>(StorageKey.Token)
      .pipe(
        switchMap((token) =>
          jwtHelper.isTokenExpired(token) ? of (token) : of(token)
        )
      )
  }
}
