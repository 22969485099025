import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { HttpService } from '@hs/core/http/http.service';
import { TRANSLOCO_CONFIGS } from '@hs/core/vendor/transloco';
import { SharedModule } from '@hs/shared/shared.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { DEFAULT_CONFIG, InstanceFactory, NgForage, NgForageConfig, NgForageOptions } from 'ngforage';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { ShellComponent } from './components/shell/shell.component';
import { SocialComponent } from './components/social/social.component';
import { routes } from './constants/routes';
import { FiltersModule } from "@hs/filters/filters.module";
import { NotFoundComponent } from "@hs/core/components/not-found/not-found.component";



@NgModule({
  declarations: [ShellComponent, MainLayoutComponent, SocialComponent, NotFoundComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
    HttpClientModule,
    TranslocoModule,
    SharedModule,
    FiltersModule,
    NgbTooltipModule
  ],
  providers: [
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: DEFAULT_CONFIG,
      useValue: {
        name: 'nta/hs'
      } as NgForageOptions
    },
    {
      provide: NgForage,
      // @ts-ignore
      useFactory: (ngForageConfig: NgForageConfig) => new NgForage({}, new InstanceFactory(ngForageConfig)),
      deps: [NgForageConfig]
    },
    ...TRANSLOCO_CONFIGS
  ],
  exports: [
    RouterModule
  ]
})
export class CoreModule { }
