import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '@hs/models';
import { BaseControlValueAccessor } from '../../base-control-value-accessor';

@Component({
  selector: 'hs-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true,
    },
  ]
})
export class SelectComponent extends BaseControlValueAccessor implements OnInit {
  @Input() label = '';
  @Input() options: SelectOption[] = [];
  isRequired = false

  get valueName() {
    const option: SelectOption = this.options.find(o => o.value === this.control.value);
    if (option) {
      return option.label;
    }
    return '';
  }

  constructor(
    @Optional() controlContainer: ControlContainer,
  ) {
    super(controlContainer)
  }

  ngOnInit(): void {
    if (this.control instanceof FormControl && this.control.validator) {
      const validator = this.control.validator(new FormControl(''))
      this.isRequired = Boolean(validator && validator.hasOwnProperty('required'))
    }
  }

  setValue(option: SelectOption) {
    this.control.setValue(option.value);
  }

  isInvalid() {
    return this.control && (
      (this.control.invalid && this.control.touched) ||
      (this.control.invalid && this.control.dirty)
    )
  }
}
