import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@hs/auth/services/auth.service';
import { UserService } from "@hs/core/services";
import { finalize, switchMap } from "rxjs/operators";

@Component({
  selector: 'hs-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

  constructor(
    private _userService: UserService,
    private authService: AuthService,
    private router: Router
  ) { }

  get userProfile () {
    return this._userService.userProfile
  }

  ngOnInit(): void {
    if (!this.userProfile) {
      this._getUserProfile();
    }
  }

  private _getUserProfile(): void {
    this._userService.getUserProfile()
      .pipe(
        switchMap( (userProfile) => {
          this._userService.userProfile = userProfile;
          return userProfile.toString();
        } ),
        finalize( () => {} )
      )
      .subscribe({
        next: () => {},
        error: err => console.error(err)
      });
  }


  onProfileClick(): void {
    this.router.navigateByUrl('/profile')
  }

  onSignOut() {
    this.authService.signOut()
      .subscribe(() => this.router.navigateByUrl('/auth/sign-in'))
  }

}
