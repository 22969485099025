<ng-container
  *ngIf="filter"
  [ngTemplateOutlet]="filter"
  [ngTemplateOutletContext]="{
    content: content,
    name: name,
    label: label,
    isFilled: isFilled
  }"
></ng-container>

<ng-template #content>
  <div class="content">
    <span class="label">Guests</span>
    <div class="increase-number-controls">
      <div class="d-flex align-items-center justify-content-center button minus" (click)="onMinus()"></div>
      <div class="d-flex align-items-center justify-content-center number">{{ guestsCount }}</div>
      <div class="d-flex align-items-center justify-content-center button plus" (click)="onPlus()"></div>
    </div>
  </div>
  <div class="filter-popup-footer">
    <button hsButton hsType="default" class="hs-btn-default clear" (click)="clearFilter()">Clear</button>
    <button hsButton hsType="primary" class="hs-btn-default" (click)="applyFilter()">Apply</button>
  </div>
</ng-template>
