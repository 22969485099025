<mat-dialog-content>
  <div class="modal-custom-header">
    <h2 class="modal-title">More filters</h2>
  </div>
  <div class="modal-content">
    <div class="close-modal" mat-dialog-close>
      <img alt="" src="../../../../assets/images/close.svg">
    </div>
    <hs-more-filter-content #content></hs-more-filter-content>
  </div>
  <div class="modal-custom-footer">
    <button hsButton hsType="default" class="hs-btn-default clear" mat-dialog-close>Discard filters</button>
    <button hsButton hsType="primary" class="hs-btn-default" [mat-dialog-close]="content.label">Show {{resultsCount}} results</button>
  </div>
</mat-dialog-content>

