import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DateRangeComponent } from './components/form-controls/date-range/date-range.component';
import { DocumentsUploadComponent } from './components/form-controls/documents-upload/documents-upload.component';
import { IncreaseNumberComponent } from './components/form-controls/increase-number/increase-number.component';
import { InputTextComponent } from './components/form-controls/input-text/input-text.component';
import { RadioGroupComponent } from './components/form-controls/radio-group/radio-group.component';
import { SelectGroupComponent } from './components/form-controls/select-group/select-group.component';
import { SelectComponent } from './components/form-controls/select/select.component';
import { InputComponent } from './components/input/input.component';
import { LogoComponent } from './components/logo/logo.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { HsButtonDirective } from './directives/hs-button.directive';
import { HsInputDirective } from './directives/hs-input.directive';
import { FooterComponent } from "@hs/shared/components/footer/footer.component";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@ngneat/transloco";
import { HeaderComponent } from "@hs/shared/components/header/header.component";
import { RouterModule } from "@angular/router";
import { TabDirective } from "@hs/shared/directives/tab.directive";
import { CodeConfirmationComponent } from "@hs/shared/components/confirmation-code/code-confirmation.component";
import { NgOtpInputModule } from "ng-otp-input";
import { ScrollNavigatorComponent } from "@hs/shared/components/scroll-navigator/scroll-navigator.component";
import { CalendarComponent } from "@hs/shared/components/calendar/calendar.component";
import { PropertyDetailsComponent } from "@hs/shared/components/property-details/property-details.component";
import { InfoModalComponent } from "@hs/shared/components/info-modal/info-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { NgImageSliderModule } from "ng-image-slider";
import { PhotoCommentsComponent } from "@hs/shared/components/photo-coments/photo-comments.component";
import { PhotosGalleryComponent } from "@hs/shared/components/photos-gallery/photos-gallery.component";
import { StepNavigationComponent } from "@hs/shared/components/step-navigation/step-navigation.component";
import { ResizeService } from "@hs/core/services/resize.service";

const EXPORT_COMPS = [
  LogoComponent,
  InputComponent,
  UserAvatarComponent,
  SelectComponent,
  RadioGroupComponent,
  InputTextComponent,
  IncreaseNumberComponent,
  DocumentsUploadComponent,
  SelectGroupComponent,
  FooterComponent,
  HeaderComponent,
  CodeConfirmationComponent,
  TabDirective,
  ScrollNavigatorComponent,
  CalendarComponent,
  PropertyDetailsComponent,
  InfoModalComponent,
  PhotoCommentsComponent,
  PhotosGalleryComponent,
  StepNavigationComponent,
  DateRangeComponent
];

const EXPORT_DIRECTIVES = [
  HsButtonDirective,
  HsInputDirective
];

@NgModule({
  declarations: [ ...EXPORT_COMPS, ...EXPORT_DIRECTIVES ],
  exports: [ ...EXPORT_COMPS, ...EXPORT_DIRECTIVES ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    RouterModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgOtpInputModule,
    MatDialogModule,
    TranslocoModule,
    NgImageSliderModule,
    NgxDropzoneModule,
    NgbDatepickerModule
  ],
  entryComponents: [
    InfoModalComponent,
    PhotoCommentsComponent
  ],
  providers: [
    ResizeService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'auth'
    }

  ]
})
export class SharedModule {
}
