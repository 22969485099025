<ng-container
  *ngIf="filter"
  [ngTemplateOutlet]="filter"
  [ngTemplateOutletContext]="{
    content: content,
    name: name,
    label: label,
    isFilled: isFilled,
    isDimension: true
  }"
></ng-container>

<ng-template #content>
  <div class="filter-controls">
    <div class="control-wrapper">
      <label for="fromDimension" class="label">From, m<sup>2</sup></label>
      <input
        type="number"
        id="fromDimension"
        class="input"
        (change)="onChange($event, 'fromDimension')"
        [(ngModel)]="fromDimension">
    </div>
    <div class="control-wrapper">
      <label for="toDimension" class="label">To, m<sup>2</sup></label>
      <input
        type="number"
        id="toDimension"
        class="input"
        (change)="onChange($event, 'toDimension')"
        [(ngModel)]="toDimension">
    </div>
  </div>
  <div class="filter-popup-footer">
    <button hsButton hsType="default" class="hs-btn-default clear" (click)="clearFilter()">Clear</button>
    <button hsButton hsType="primary" class="hs-btn-default" (click)="applyFilter()">Apply</button>
  </div>
</ng-template>
