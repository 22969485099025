import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@hs/core/services';
import {UserEntity} from "@hs/core/interfaces/user.interface";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService
  ) { }

  signIn({ email, password }: { email: string; password: string }) {
    const url = this.router.createUrlTree(['/api/login'], { queryParams: { authorizationType: 'EMAIL', password, value: email } }).toString();
    return this.http.stopAddingAuthorizationToken().post<{ data: string }>(url, {});
  }

  signUp(user: UserEntity) {
    const url = this.router.createUrlTree(['/api/registration']).toString();
    return this.http.stopAddingAuthorizationToken().post<{ data: string }>(url, user);
  }

  sendEmailVerificationCode(email: string) {
    const url = this.router.createUrlTree(['/api/request-reg-email-verification-key'], {queryParams: { email }}).toString();
    return this.http.stopAddingAuthorizationToken().post<{ data: string }>(url, {});
  }

  verifyEmailVerificationCode(token: string, key: string) {
    const url = this.router.createUrlTree(['/api/verify-reg-email-verification-key'], {queryParams: { token, key }}).toString();
    return this.http.stopAddingAuthorizationToken().post<{ data: boolean }>(url, {});
  }

  sendUpdateEmailVerificationCode(newEmail: string) {
    const url = this.router.createUrlTree(['/api/request-update-email-verification-key'], {queryParams: { newEmail }}).toString();
    return this.http.post<{ data: string }>(url, {});
  }

  verifyUpdateEmailVerificationCode(newEmail: string, key: string) {
    const url = this.router.createUrlTree(['/api/verify-update-email-verification-key'], {queryParams: { newEmail, key }}).toString();
    return this.http.post<{ data: boolean }>(url, {});
  }

  verifyEmailRestoreCode(email: string, key: string) {
    const url = this.router.createUrlTree(['/api/verify-key/by-email'], {queryParams: { email, key }}).toString();
    return this.http.stopAddingAuthorizationToken().post<{ data: string }>(url, {});
  }

  resetPasswordByEmail({ email }: { email: string }) {
    const url = this.router.createUrlTree(['/api/reset-password/by-email'], { queryParams: { email } }).toString();
    return this.http.stopAddingAuthorizationToken().post<{ data: any }>(url, {});
  }

  resetPassword(newPassword: string, token: string ) {
    const url = this.router.createUrlTree(['/api/reset-password'], { queryParams: { newPassword, token } }).toString();
    return this.http.stopAddingAuthorizationToken().post<{ data: any }>(url, {});
  }

  signOut() {
    return this.storage.clear();
  }


}
