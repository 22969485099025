import { Routes } from '@angular/router';
import { AuthenticatedGuard } from '@hs/auth/guards/authenticated.guard';
import { ShellComponent } from '@hs/core/components/shell/shell.component';
import { NotFoundComponent } from "@hs/core/components/not-found/not-found.component";

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: 'auth',
    loadChildren: () => import('@hs/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    canActivateChild: [AuthenticatedGuard],
    component: ShellComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('@hs/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'city',
        loadChildren: () => import('@hs/city/city.module').then(m => m.CityModule)
      },
      {
        path: 'add-my-property',
        loadChildren: () => import('@hs/add-my-property/add-my-property.module').then(m => m.AddMyPropertyModule)
      },
      {
        path: 'places',
        loadChildren: () => import('@hs/places/places.module').then(m => m.PlacesModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('@hs/profile/profile.module').then(m => m.ProfileModule)
      }
    ]
  },
  {
    path: 'not-found',
    pathMatch: 'full',
    component: NotFoundComponent
  }
];
