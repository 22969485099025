import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'hs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() hideShadow: boolean;
  @Input() customClass: string;
  @Input() filters: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
