import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SearchDTO, SearchField } from "@hs/core/interfaces/search.interface";
import { SearchService } from "@hs/core/services/data/search.service";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MoreFiltersModalComponent } from "@hs/filters/components/more-filters-modal/more-filters-modal.component";
import { Router } from "@angular/router";

@Component({
  selector: 'hs-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() defaultPopup: string;
  @Input() clearAllSubject: Subject<void>;
  public activePopup: string | null;
  public defaultFilters: SearchDTO;
  public isMoreFiltersOpened: boolean;
  private _destroyed$ = new Subject<void>();

  constructor(
    private _searchService: SearchService,
    private _router: Router,
    public dialog: MatDialog,
  ) { }

  get filters() {
    return this._searchService.searchDTO;
  }

  get moreFiltersLabel() {
    return this._searchService.moreFiltersLabel
  }

  get moreFiltersIsFilled() {
    return this._searchService.moreFiltersIsFilled
  }

  ngOnInit(): void {
    this.activePopup = this.defaultPopup ? this.defaultPopup : null;
    this._searchService.setDefaultSearchDTO();
    this.defaultFilters = this._searchService.searchDTO;
    if (this.clearAllSubject) {
      this.clearAllSubject.subscribe(() => {
        this.clearAll();
      })
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onLabelClick(popupName: string): void {
    this.activePopup = this.activePopup === popupName ? null : popupName;
  }

  public onSubstrateClick(): void {
    this.activePopup = null;
  }

  public clearAll() {
    this._searchService.searchDTO = this.defaultFilters;
    this._searchService.moreFiltersLabel = 'More';
    this._searchService.moreFiltersIsFilled = false;
    this._router.navigateByUrl('/places/search');
  }

  public onMoreFiltersClick(): void {
    this.isMoreFiltersOpened = true;
    this.activePopup = null;
    const dialogRef = this.dialog.open(MoreFiltersModalComponent, {
      panelClass: 'more-filter-dialog-container',
      width: '900px',
      data: {
        filters: this.filters
      },
    });

    dialogRef.afterClosed().subscribe(label => {
      this.isMoreFiltersOpened = false;
      this.changeFilters();
    });
  }

  public changeFilters(): void {
    this.activePopup = null;
    const filters = [];
    Object.keys(this.filters).forEach(filterName => {
      if (this.filters[filterName]) {
        filters.push(`${filterName}=${JSON.stringify(this.filters[filterName])}`);
      }
    });
    this._router.navigateByUrl(`/places/search?${filters.join('&')}`);
  }
}
