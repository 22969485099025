import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DEFAULT_CONFIG, INVALID_CONFIG, VALID_CONFIG } from "@hs/auth/constants/input-styles";

@Component( {
  selector: 'hs-code-confirmation',
  templateUrl: './code-confirmation.component.html',
  styleUrls: [ './code-confirmation.component.scss' ]
} )
export class CodeConfirmationComponent implements OnInit, OnChanges {
  public config = {
    allowNumbersOnly: true,
    length: 6,
    placeholder: '',
    inputStyles: DEFAULT_CONFIG
  };

  public timer: number = 0;
  public timerString: string;
  public interval;

  public isLoading: boolean;

  private _code;

  @Input() title: string;
  @Input() isCodeValid: boolean;
  @Input() isVerified: boolean;
  @Input() isCodeComplete: boolean;

  @Output() resendCode = new EventEmitter();
  @Output() verifyCode = new EventEmitter<string>();

  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.isCodeValid && !changes.isCodeValid.isFirstChange())
      || (changes.isCodeComplete && !changes.isCodeComplete.isFirstChange())
      || (changes.isVerified && !changes.isVerified.previousValue && changes.isVerified.currentValue)) {
      this.isLoading = false;
      this.isCodeComplete = true;
      const styles = this.isCodeValid ? VALID_CONFIG : INVALID_CONFIG;
      this.completeInputStyles(styles);
    }

    if (changes.isVerified && changes.isVerified.previousValue && !changes.isVerified.currentValue) {
      this.isLoading = false;
      this.isCodeComplete = false;
      this.config.inputStyles = DEFAULT_CONFIG;
    }
  }

  public onCodeChange(code) {
    this._code = code;
    this._validateCode();
  }

  public startTimer() {
    this.timer = 59;

    this.interval = setInterval( () => {
      if ( this.timer > 0 ) {
        this.timer --;
        const prettyTime = this.timer >= 10 ? this.timer : `0${ this.timer }`
        this.timerString = `00:${ prettyTime }`
      } else {
        this.timer = 0;
        this.timerString = '';
        clearInterval( this.interval );
      }
    }, 1000 )
  }

  public resendConfirmationCode(isTimerEnabled: boolean = true): void {
    if ( isTimerEnabled && this.timer > 0 ) {
      return;
    }
    this.startTimer();
    this.isCodeComplete = false;
    this.isLoading = true;
    this.ngOtpInput.setValue(null);
    this.resendCode.next()
  }

  public completeInputStyles(additionalConfig): void {
    this.config.inputStyles = {
      ...this.config.inputStyles,
      ...additionalConfig
    }
  }

  private _validateCode(): void {
    this.isCodeComplete = this._code.length === this.config.length;

    if ( !this.isCodeComplete || this.isLoading) {
      this.config.inputStyles = DEFAULT_CONFIG;
      return;
    }

    this.isCodeComplete = false;
    this.isLoading = true;
    this.verifyCode.next(this._code);
  }
}
