import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SearchService } from "@hs/core/services/data/search.service";

@Component({
  selector: 'hs-profile-more-filters-modal',
  templateUrl: './more-filters-modal.component.html',
  styleUrls: [ './more-filters-modal.component.scss' ]
})
export class MoreFiltersModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _searchService: SearchService
  ) {}

  get resultsCount() {
    return this._searchService.resultsCount
  }

  ngOnInit(): void {}
}
