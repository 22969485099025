import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'hs-photo-comments',
  templateUrl: './photo-comments.component.html',
  styleUrls: ['./photo-comments.component.scss']
})
export class PhotoCommentsComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  public selectedImage: number = 0;
  public description: string;
  public files = [];

  ngOnInit(): void {
    this.files = this.data?.files || [];
  }

  public onImageClick(index: number): void {
    this.selectedImage = index;
  }

  public onInputBlur(event): void {
    if (typeof this.selectedImage !== 'number') {
      return;
    }
    this.files[this.selectedImage].description = event.target.value;
  }

}
